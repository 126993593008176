import React from 'react';

const Title = ({ children }) => (
  <h1
    className="
      xs:w-4/5 sm:w-2/3 md:w-4/5 lg:w-2/3 xl:w-3/5
      text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-blue font-bold
      lg:leading-title leading-mobile-title
    "
  >
    {children}
  </h1>
);

export default Title;
