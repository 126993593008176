import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const IMAGE_PROPS = {
  width: 362,
  height: 246,
  quality: 100,
  className: `
    h-full sm:h-auto lg:h-full
    w-auto sm:w-full lg:w-auto
  `,
};

export default function Premium() {
  return (
    <section className="container py-10 xl:py-24">
      <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-blue lg:mb-20 md:mb-16 sm:mb-10 mb-6">
        Deliver premium experience with...
      </h2>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        <Section
          title="Live multilingual streaming"
          text="Up to 5 languages streaming live"
          image={
            <StaticImage
              src="../images/premium/languages.png"
              alt="Language dropdown"
              {...IMAGE_PROPS}
            />
          }
        />

        <Section
          title="Fully branded experiences"
          text="Custom mini-site, graphics and title animations"
          image={
            <StaticImage
              src="../images/premium/browser.png"
              alt="Browser window"
              {...IMAGE_PROPS}
            />
          }
        />

        <Section
          title="Audio & Visual consulting"
          text="We help you find the right AV solution for your event"
          image={
            <StaticImage
              src="../images/premium/av-consulting.png"
              alt="Microphone and camera"
              {...IMAGE_PROPS}
            />
          }
        />
      </div>
    </section>
  );
}

function Section({ title, text, image }) {
  return (
    <div
      className="
        col-span-1 flex flex-col sm:flex-row lg:flex-col items-center
        bg-dark-white rounded-md
      "
    >
      <div
        className="
          flex items-center justify-center self-center
          sm:w-1/2 lg:w-full
          p-2 sm:p-2 sm:pr-0 lg:p-1 xl:p-0
        "
      >
        {image}
      </div>

      <div
        className="
          w-full sm:w-1/2 lg:w-full self-center
          p-6 pt-0 sm:p-8 lg:pt-0
        "
      >
        <p className="text-black text-xl font-bold leading-relaxed">{title}</p>
        <p className="text-black text-opacity-50 text-xl font-bold leading-relaxed">{text}</p>
      </div>
    </div>
  );
}
