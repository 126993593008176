import React, { useState } from 'react';

const ALLQUESTIONS = [
  {
    type: 'all',
    question: 'Can my audience participate by submitting questions?',
    answer: (
      <p>
        Yes, our platform has a built in Q&amp;A form. Your audience can submit questions right at
        the streaming page.
      </p>
    ),
  },
  {
    type: 'all',
    question:
      'Can my event be streamed simultaneously on multiple platforms like YouTube, Facebook and Twitter?',
    answer: (
      <p>
        Yes, we can stream to the most popular social media platforms parallel to streaming to
        Livecasts so you can maximise your outreach.
      </p>
    ),
  },
  {
    type: 'virtual',
    question:
      'Which video conferencing software will work for live streaming my virtual event on Livecasts?',
    answer: (
      <p>
        We work with all the popular platform including but not limited to Zoom, Microsoft Teams,
        Google Hangouts, Cisco Webex, Skype etc.
      </p>
    ),
  },
  {
    type: 'all',
    question: 'Can presentation slides and pre-recorded videos be displayed on the live stream?',
    answer: <p>Yes, we can incorporate videos and other visual elements in the stream.</p>,
  },
  {
    type: 'on-site',
    question: 'What are the requirements for live streaming an on-site event?',
    answer: (
      <>
        <p>
          In case your event is held physically at a venue, we need the following from in order to
          guarantee seamless services on our end.
        </p>
        <p>
          1. <strong>Wired internet connection</strong> with low latency (up to 10ms) and at least 6
          Mbps upload speed with no firewall or other restrictions. You can test your internet
          connection using Speedtest.
          <br />
          2. <strong>Audio feed</strong> of the room amplification system. <br />
          3. <strong>Lighting</strong> of the stage or the set where your speakers will present.
        </p>
        <p>
          If you don’t have audio/visual services arranged yet, we are happy to recommend our tested
          and proven AV partner, who will provide outstanding services for a reasonable price.
        </p>
      </>
    ),
  },
  {
    type: 'on-site',
    question: 'Do I need my own camera and microphone equipment for on-site events?',
    answer: (
      <>
        <p>
          If your event is held in an auditorium that is already equipped with cameras and
          amplification, our technician will connect our streaming equipment to the existing audio
          and video feed.
        </p>
        <p>
          If you don’t have audio/visual services arranged yet, we are happy to recommend our tested
          and proven AV partner, who will provide outstanding services for a reasonable price.
        </p>
      </>
    ),
  },
  {
    type: 'on-site',
    question:
      'Can I add a remote speaker to the live event via Skype or similar service while holding an onsite event?',
    answer: (
      <>
        <p>
          Yes, we can assist you with this, working together with the audio/visual company providing
          amplification and projection at the venue.
        </p>
        <p>
          If you don’t have audio/visual services arranged yet, we are happy to recommend our tested
          and proven AV partner, who will provide outstanding services for a reasonable price.
        </p>
      </>
    ),
  },
];

const FAQ = (props) => {
  let [activeIndex, setActiveIndex] = useState(null);
  let questions;

  if (props.type == 'virtual') {
    questions = ALLQUESTIONS.filter((x) => x.type != 'on-site');
  } else if (props.type == 'on-site') {
    questions = ALLQUESTIONS.filter((x) => x.type != 'virtual');
  } else {
    questions = ALLQUESTIONS;
  }

  return (
    <section className="bg-light-grey">
      <div className="container grid grid-cols-12 sm:gap-8 border-b border-border-grey">
        <div className="col-span-12 lg:col-span-10 lg:col-start-2 py-24">
          <h2 className="pb-16 text-center text-3xl font-bold text-blue border-b border-border-grey">
            Frequently asked questions about webcasting
          </h2>

          <div className="w-full">
            {questions.map(({ question, answer }, index) => (
              <AccordionItem
                key={index}
                question={question}
                answer={answer}
                isActive={activeIndex == index}
                onClick={() => setActiveIndex(activeIndex != index ? index : null)}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

function AccordionItem({ question, answer, isActive, onClick }) {
  return (
    <div className="w-full border-b border-border-grey py-6">
      <button
        onClick={onClick}
        type="button"
        className={`
          flex items-center w-full
          text-lg text-black text-left font-bold
          focus-ring transition
          ${isActive ? 'text-primary' : ''}
        `}
      >
        <span className="flex-auto w-4/5">{question}</span>
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={`mx-4 transition transform ${isActive ? 'rotate-180' : 'rotate-0'}`}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.4876 7.12402C14.8071 6.8045 14.8071 6.28646 14.4876 5.96693C14.1681 5.64742 13.65 5.64742 13.3305 5.96693L8.99998 10.2975L4.66943 5.96693C4.34991 5.64742 3.83186 5.64742 3.51235 5.96693C3.19283 6.28646 3.19283 6.8045 3.51235 7.12402L8.42143 12.0331C8.74096 12.3526 9.259 12.3526 9.57852 12.0331L14.4876 7.12402Z"
            fill="currentColor"
          />
        </svg>
      </button>

      {isActive && (
        <div className="pt-6 text-lg leading-relaxed text-bluesh-grey space-y-6 overflow-hidden">
          {answer}
        </div>
      )}
    </div>
  );
}

export default FAQ;
