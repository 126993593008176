import React from 'react';
// import { Link } from 'gatsby';
import Title from './Title';
import Button from './QuoteButton';

export default function Hero(props) {
  return (
    <div className=" container sm:my-20 my-12">
      <Title>{props.title}</Title>
      <p className="sm:w-2/3 xl:w-1/2 text-blue text-lg leading-relaxed mt-4 mb-6 sm:my-8">
        {props.subtitle}
      </p>
      <div className="flex items-center space-x-6">
        <Button />
        {/* <Link
          to="#"
          className="text-primary text-base sm:text-lg font-bold px-2 sm:px-4 py-2 hover:underline"
        >
          See pricing
        </Link> */}
      </div>
    </div>
  );
}
