import React from 'react';
import comment from '../images/included/comment.svg';
import dataBar from '../images/included/data-bar.svg';
import headset from '../images/included/headset.svg';
import infinity from '../images/included/infinity.svg';
import list from '../images/included/list.svg';
import storage from '../images/included/storage.svg';
import tablet from '../images/included/tablet.svg';
import time from '../images/included/time.svg';
import video from '../images/included/video.svg';

export default function AllIncluded() {
  const includedItems = [
    {
      title: 'Unlimited viewers ',
      text: 'Scale up your event without limits to the size of your audience.',
      logo: infinity,
    },
    {
      title: 'Dedicated streaming page',
      text: 'Include key information, such as programme, speaker bios and more.',
      logo: tablet,
    },
    {
      title: 'On-demand storage',
      text: 'Watch the stream on-demand immediately after the event is over.',
      logo: storage,
    },
    {
      title: 'Moderated Q&A',
      text:
        'Involve your audience in the discussion right at the event page, making it interactive and simple to participate.',
      logo: comment,
    },
    {
      title: 'Branded player with client logo',
      text:
        'Your event page, your identity. Your logo and branding will be visible both on the page and in the player.',
      logo: video,
    },
    {
      title: 'Insightful analytics',
      text:
        ' Get viewer statistics right after your event. Livecasts works with privacy-friendly analytical tools.',
      logo: dataBar,
    },
    {
      title: 'Interactive program',
      text: 'Boost the viewing experience with live titles, captions and speaker names.',
      logo: list,
    },
    {
      title: 'End-to-end support',
      text:
        'Our team will take care of all technical aspects of your event, letting you focus on the more important things.',
      logo: headset,
    },
    {
      title: 'Raw footage within 24 hours',
      text: 'Get the recorded footage of your event within 24 hours after you event has ended.',
      logo: time,
    },
  ];
  return (
    <div className="container mt-12 sm:mt-16 md:mt-24 mb-16 sm:mb-20 md:mb-28 lg:mb-32">
      <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-blue md:mb-20 mb-10">
        All Included
      </h2>
      <div
        className="
          grid lg:grid-cols-12 sm:grid-cols-2 grid-cols-1
          sm:gap-x-8 md:gap-x-14 xl:gap-x-20 md:gap-y-14 gap-y-8
        "
      >
        {includedItems.map((item, index) => {
          return (
            <div className="cols-span-1 lg:col-span-4" key={index}>
              <img src={item.logo} alt={item.title} className="mb-4" />
              <p className="text-black text-opacity-50 text-lg md:text-xl font-bold leading-relaxed">
                <strong className="text-black text-opacity-100">{item.title}</strong> {item.text}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
