import React from 'react';
import { Link } from 'gatsby';
import logo from '../images/header/livecasts-logo.svg';
import onePercentLogo from '../images/one-percent-for-the-planet.svg';
import linkedIn from '../images/linkedin.svg';
import facebook from '../images/facebook.svg';
import twitter from '../images/twitter.svg';

export default function Footer(props) {
  return (
    <footer className={`bg-light-grey ${props.className}`}>
      <div className="container pb-16">
        <div className="grid xl:grid-cols-12 grid-cols-2 sm:grid-cols-4 gap-8 pt-8">
          <div className="col-span-2 xl:col-span-3  text-sm text-blue text-opacity-80">
            <div className="mb-4">
              <img src={logo} alt="Livecasts" />
            </div>
            <p>Made in Europe</p>
            <p>Livecasts is a product of Evermore</p>
            <p className="flex items-center space-x-1 mt-2">
              <span>We are member of</span>
              <a
                href="https://weareevermore.com/one-percent-for-the-planet"
                target="_blank"
                rel="nofollow noreferrer"
              >
                <img src={onePercentLogo} alt="One percent for the Planet" />
              </a>
            </p>
          </div>
          <div className="col-span-1 xl:col-span-2 xl:col-start-8">
            <h5 className="text-base text-blue font-bold">Services</h5>
            <div className="mt-7 space-y-4 text-sm">
              <Link to="/virtual-events" className="footer-link focus-ring">
                Virtual Events
              </Link>
              <Link to="/on-site-events" className="footer-link focus-ring">
                On-site Events
              </Link>
            </div>
          </div>
          <div
            className="
              col-span-1 xl:col-span-2 xl:col-start-11
              xs:justify-self-end sm:justify-self-auto
            "
          >
            <h5 className="text-base text-blue font-bold">Contacts</h5>
            <div className="mt-7 space-y-4 text-sm">
              <a href="mailto:info@livecasts.eu" className="footer-link">
                info@livecasts.eu
              </a>
              <a href="tel:32 2 881 01 15" className="footer-link">
                +32 2 881 01 15
              </a>
            </div>
          </div>
        </div>
        <div
          className="
            flex flex-col-reverse xs:flex-row-reverse xs:justify-between
            sm:grid xl:grid-cols-12 sm:grid-cols-4 sm:gap-8 mt-14
          "
        >
          <p className="col-span-2 xl:col-span-3 text-sm text-blue text-opacity-50">
            © 2021. All rights reserved
          </p>
          <div
            className="
              col-span-1 xl:col-span-2 sm:col-start-4 xl:col-start-11
              flex items-center space-x-4 opacity-60 mb-6 xs:mb-0
          "
          >
            <a
              href="https://www.linkedin.com/showcase/livecasts/"
              rel="nofollow noreferrer"
              className="social-link focus-ring"
            >
              <img src={linkedIn} alt="LinkedIn" />
            </a>
            <a
              href="https://www.facebook.com/livecasts.eu"
              rel="nofollow noreferrer"
              className="social-link focus-ring"
            >
              <img src={facebook} alt="Facebook" />
            </a>
            <a
              href="https://twitter.com/livecasts"
              rel="nofollow noreferrer"
              className="social-link focus-ring"
            >
              <img src={twitter} alt="Twitter" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
