import React from 'react';
import Button from './QuoteButton';
import logo from '../images/livecasts-logo-small.png';

export default function Hero() {
  return (
    <div className="container my-16 sm:my-20 md:my-24 lg:my-32 xl:my-40">
      <div className="text-center xl:w-4/5 mx-auto">
        <img src={logo} alt="Logo" className="mb-6 mx-auto lg:w-28 md:w-24 w-20" />
        <h2 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-blue font-bold">
          Ready to start live streaming with Livecasts?
        </h2>
        <div className="flex justify-center mt-8">
          <Button />
        </div>
        <p className="text-blue text-center text-base font-bold mt-6">Or call +32 2 881 01 15</p>
      </div>
    </div>
  );
}
