import React from 'react';
import bnp from '../images/partners/bnp-logo.svg';
import epp from '../images/partners/epp-logo.svg';
import eu from '../images/partners/eu-parliament-logo.svg';
import euronews from '../images/partners/euronews-logo.svg';
import google from '../images/partners/google-logo.svg';
import iss from '../images/partners/iss-logo.svg';
import keytrade from '../images/partners/keytrade-logo.svg';
import petercam from '../images/partners/petercam-logo.svg';
import renew from '../images/partners/renew-logo.svg';

export default function Partners() {
  const partnersLogo = [
    { name: 'BNP', image: bnp },
    { name: 'Euronews', image: euronews },
    { name: 'EU Parliament', image: eu },
    { name: 'Renew Europe', image: renew },
    { name: 'Petercam', image: petercam },
    { name: 'ISS', image: iss },
    { name: 'EPP', image: epp },
    { name: 'Keytrade', image: keytrade },
    { name: 'Google', image: google },
  ];

  return (
    <div className="container md:mt-16 mt-8 md:mb-16 mb-8">
      <div className="grid lg:grid-cols-9 md:grid-cols-5 grid-cols-3">
        {partnersLogo.map((item, index) => {
          return (
            <div className="col-span-1 flex justify-center" key={index}>
              <img src={item.image} alt={item.name} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
